/* Custom CSS */

.site-main{
	flex: 1;
}

.center{
	width: 50%;
	margin: 0 auto;
	text-align: center;
}

.center-vertical{
		display: flex;
  justify-content: center;
  align-items: center;
}

// MENU
.nav-container{
//	@include xy-grid-container;
	max-width: $global-width;
	margin: 0 auto;
}

.nav {
	@extend .menu;
  @extend .align-middle;
  font-size: 1.25rem;

	a{
		color: black;
		&:hover{
			color: get-color(primary);
		}
	}
}

// INDEX HEADER

$index-header-height: 40vh;

.index-header {
  background: get-color(primary);
  background-size: cover;
  height: $index-header-height;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .index-header-text {
    color: $white;
    text-shadow: 1px 1px 2px $black;

	  h3{
		  @include breakpoint(850px down){
			font-size: 1.1875rem;
		  }
	  }

	  h1{
		  word-spacing: 40px;
		  margin-bottom: 1.5vh;
		  @include breakpoint(850px down){
			font-size: 1.5rem;
		  }
		   @include breakpoint(500px down){
			white-space: pre-line;
		  }
	  }
	  h1 span{
		text-decoration: none;
		border-bottom: 2px solid $white;
		padding-bottom: 5px;
		margin-bottom: 1.5vh;

	 }
  }
}

//Inventure Four Up Services
.four-up {
  text-align: center;
  padding: 2rem 0;
  background-color: whitesmoke;

  .fa {
	font-size: 3rem;
    margin-bottom: 1rem;
    color: $black;
  }

  .four-up-title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 40em) {
    text-align: left;
  }
}

.four-up-headline {
  text-align: center;
  font-size: 2rem;
  color: $black;
}

.four-up-subheadline {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.125rem;
  color: $black;
}

//Inventure Full Solution Design Model
.solution {
  background-color: get-color(primary);
  color: $white;
  text-align: center;
  padding: 4rem 0;
	@include breakpoint(small only) {
	  padding: 1rem 0;
	}

  .fa {
    margin-bottom: 1rem;
    color: $black;
  }

  .solution-title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

 .solution-headline {
   text-align: center;
   font-size: 2rem;
   @include breakpoint(850px down){
    font-size: 1.5rem;
    }
   span{
    border-bottom: 2px solid $white;
   }
   
 }

  @media screen and (min-width: 40em) {
    text-align: left;
  }
}

//Inventure Services
.services-container{
  background-color: whitesmoke;
}

.services {
  text-align: center;
  padding: 2rem 0;
  
  .fa {
	font-size: 3rem;
    margin-bottom: 1rem;
    color: $black;
  }

	.img-software{
		background-image: url("../img/services/coding-o.svg");
		background-size: cover;
		background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: auto;
    object-fit: cover;
	}

	.img-engineering{
		background-image: url("../img/services/engineer-project-o.svg");
		background-size: cover;
		background-repeat: no-repeat;
    background-position: center bottom;
    width: 100%;
    height: auto;
    object-fit: cover;
	}

	.img-pcb{
		background-image: url("../img/services/pcb-o.svg");
		background-size: cover;
		background-repeat: no-repeat;
    background-position: center bottom;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

   .img-manufacturing{
		background-image: url("../img/services/pcb-assembly-line-manufacturing-o.svg");
		background-size: cover;
		background-repeat: no-repeat;
    background-position: center bottom;
    width: 100%;
    height: auto;
    object-fit: cover;
	}
  
  // .img-manufacturing{
	// 	background-image: url("../img/services/surface-mount-manufacturing.jpg");
	// 	background-size: cover;
	// 	background-repeat: no-repeat;
	// 	background-position: center bottom;
	// }

  .service-title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .service-desc {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 40em) {
    text-align: left;
  }
}

//Request a quote / Contact Us
$contact-us-section-padding: 2rem;
$contact-us-section-bg: $body-background;

.contact-us-section {
  padding: $contact-us-section-padding;
  background: $contact-us-section-bg;
  background-color: whitesmoke;

  .contact-us-section-left {
    padding-right: $contact-us-section-padding;
    border-bottom: 2px solid $light-gray;
    padding-bottom: $contact-us-section-padding;
    margin-bottom: $contact-us-section-padding;
    font-weight: 700;

    @include breakpoint(medium) {
      border-right: 1px solid $light-gray;
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .contact-us-section-right {
    padding-left: $contact-us-section-padding;

    @include breakpoint(medium) {
      border-left: 1px solid $light-gray;
    }
  }

  .contact-us-header {
    margin-bottom: $contact-us-section-padding;

    @media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {
      font-size: 32px;
    }
  }

  .responsive-embed {
    margin-bottom: 0;
    padding-bottom: 55%;
  }
}

.contact-us-form {
  input[type=text],
  input[type=email],
  textarea {
    margin-bottom: 1.5rem;
  }
}

.contact-us-form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type=submit] {
    border-radius: 5000px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .contact-us-file-button {
    background: transparent;
    color: $dark-gray;
  }
}

.contact-us-list {
  list-style-type: none;
  margin: 0;

  li {
    a {
      color: $black;
    }
  }

  li::before {
    margin-right: 2rem;
    font-size: 2rem;
    vertical-align: middle;
    color: $black;
    font-family: 'FontAwesome';
  }

  // generates icons - change fontawesome content codes below to change icons
  .address::before {
    content: '\f276';
    margin-left: 0.4rem;
    margin-right: 2.3rem;
  }

  .email::before {
    content: '\f0e0';
  }

  .phone::before {
    content: '\f095';
  }

  .fax::before {
    content: '\f1ac';
  }
}


//footer
$footer-bg: #2c3840;

.site-footer-sticky-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	width:100%;
}

.site-footer {
  background: $footer-bg;
  color: lighten($footer-bg, 40%);
  padding: 2rem 0 0;
  width: 100%;
  bottom: 0;

  li::before {
    margin-right: 0rem;
  }
  a {
    color: lighten($footer-bg, 40%);;
  }
  .address::before {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .column-block {
    margin-bottom: 30px;
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }
}

.site-footer-name {
  color: $white;
  font-size: 1.5rem;
  text-align: center;
}

.site-footer-title {
  color: $white;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  text-align: center;
}

.site-footer-block {
  display: flex;
  margin-bottom: 1rem;
	justify-content: center;
    align-items: center;


  p {
    margin-left: 1rem;
	margin-bottom: 0;
    line-height: 1.125rem;
  }
}

.site-footer-bottom {
  background: darken($footer-bg, 20%);
  padding: 1rem 0;

  p,
  .menu {
    margin-bottom: 0;
  }

  .site-footer-bottom-links {
    justify-content: flex-end;

    a {
      color: lighten($footer-bg, 40%);
    }

    @include breakpoint(medium down) {
      justify-content: center;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
  }
}


//DEFAULT HEADER
$default-header-height: 40vh;

.default-header {
  background-color: get-color(primary);
  color: $white;
  text-align: center;
  padding: 4rem 0;
	@include breakpoint(small only) {
	  padding: 1rem 0;
  }


  .small{
    height: 3vh;
    padding: 2rem;
  }

  .default-header-text {
    color: $white;
    text-shadow: 1px 1px 2px $black;
	  h1 span{
      margin-bottom: 1.5vh;
      text-decoration: none;
      padding-bottom: 5px;
      border-bottom: 2px solid $white;
		  @include breakpoint(850px down){
			font-size: 1.5rem;
		  }
		   @include breakpoint(500px down){
      white-space: pre-line;
		  }
	  }
  }
}

.default-header-small {
  background-color: get-color(primary);
  color: $white;
  text-align: center;
  margin-bottom: 0rem;
  padding: 1rem;
}
  


//ABOUT US
.about-us-container{
  background-color: whitesmoke;
}

.about-us {
  color: $black;
  
  padding-top: 3rem;
  padding-bottom: 3rem;

  p{
    padding: .5rem;
    font-size: 1.5rem;
    //font-weight: 700;
  }
}